class CardSectionCounter {
  cardSectionCount() {
    document.querySelectorAll('.cards-widget').forEach((cards) => {
      cards.querySelectorAll('.card-item').forEach((card) => {
        card.style.setProperty('--card-item-sections', card.children.length);
      });
    });
  }

  init() {
    this.cardSectionCount();
  }
}

export default CardSectionCounter;
